<template>
<!-- 这个页面是博客的主页面 -->
  <div id="app" class="home" >
    <!-- banner -->
    <div class="home-banner">
      <div class="banner-container">
      <img class="img-g" src="../assets/logo.png">
      <h2 class="wenzhangzongshu">文章 {{pagination.total}} | 标签{{tagList.length}} | 分类{{typeList.length}}</h2>
        <h1 class="blog-title animated zoomIn">
                  waffleのblog
                  <br>
          众生相碰，梦到剧终，自由之志，不懈追求
        </h1>

        <div class="ui wechat-qr flowing popup transition hidden">
          <!-- 首页图片月亮 -->
          <img src="../assets/images/71f294ddb4a4984817cb5c6c1e3e6102.jpg" alt="" class="ui rounded image" style="width: 110px">
        </div>
        <!-- 联系方式 -->
        <div class="blog-contact animated zoomIn">
          <a class="github circular icon button" data-content="https://github.com/xuyangwei" data-position="bottom center" style="margin-right: 50px"><i class="github icon"></i></a>
          <a class="wechat circular icon button" style="margin-right: 50px"><i class="weixin icon"></i></a>
          <a class="qq circular icon button" data-content="429303349" data-position="bottom center"><i class="qq icon"></i></a>
          <!-- <a class="qq circular icon button" style="margin-left: 50px" @click="openWeChatPay" data-content="博客整合资料，点击获取" data-position="bottom center"><i class="file alternate outline icon"></i></a> -->
        </div>
        <!-- 向下滚动 -->
        <div class="scroll-down" @click="scrollDown">
          <h4>向下滚动<i class="el-icon-arrow-down"></i></h4>
        </div>
      </div>
      <!--中间内容,如果太窄了可放到container    <div  class="m-container m-padded-tb-big">-->
    </div>
    <div  class="m-home">
      <div class="ui container">
        <div class="ui stackable grid" style="width:'80%'">
          <!--左边博客列表-->
          <div class="eleven wide column" style="width:'100%';height:'100%'">
            <!--header-->
            <div class="ui top attached segment" :style="{width: '100%'}">
              <div class="ui middle aligned two column grid my-shadow">
                <div class="column">
                  <h3 class="ui my-blue header" >博客</h3>
                </div>
                <div class="right aligned column"><h4 class="ui header m-inline-block m-text" v-if="pagination.queryString!=='' && pagination.queryString!==null" style="height: 1px !important;">根据"{{pagination.queryString}}"的搜索结果</h4>
                  共 <h2 class="ui orange header m-inline-block m-text-thin">{{pagination.total}}</h2> 篇
                </div>
              </div>
            </div>

            <!--content-->
            <!-- <div class="ui attached  segment my-blog-shadow" style=" background-color: rgba(255, 255, 255, 0);"> -->

              <div class="ui padded vertical segment m-padded-tb-largev" v-for="item in dataList" :key="item.blogId">
                <!-- <el-card class="card-v" style="padding:0;margin:0"> -->
                  <div class="card-x" style="width:'100%';height:220px;padding:0;margin:0; background-color: rgba(255, 255, 255, 0.7);position: relative;overflow: hidden;">
                  <!-- 卡片中定义网格布局 -->
                <div class="ui mobile reversed stackable grid" style="width: 100%; height: 100%;gap: 0; padding:0;margin:0">
                  <!-- 左边九列布局 -->
                  <div class="nine wide column" style="cursor:pointer;">
                    <!-- 九列布局第一行 hearder占位 -->
                        <h3 class="ui header" style="color:rgb(135, 206, 235);display: flex;justify-content: center;align-items: center;margin-bottom:20px;margin-top:20px" @click="toBlog(item.blogId)">{{item.title}}</h3>
                        <!-- 九列布局第二行又是一个网格布局 -->
                    <div class="ui grid" style="display: flex;justify-content: start;align-items: center; margin-top: 50px; margin-bottom: 20px;margin-left:20px" >
                      <!-- 第一行文本介绍 -->
                    <p class="m-text" @click="toBlog(item.blogId)">{{item.description}}</p>
                    <!-- 占据11列的宽度 -->
                      <div class="twelve wide column" style="display: flex;justify-content:start;">
                        <div class="ui mini horizontal link list">
                          <div class="item">
                            <img v-bind:src=item.avatar class="ui avatar image">
                            <div class="content"><a class="header">{{item.nickname}}</a></div>
                          </div>
                          <div class="item">
                            <i class="calendar icon"></i> {{item.createTime}}
                          </div>
                          <div class="item">
                            <i class="eye icon"></i> {{item.views}}
                          </div>
                          <div class="item">
                            <i class="thumbs up outline icon"></i> {{item.thumbs}}
                          </div>
                        </div>
                      </div>
                      <!-- 剩下的行数右边对其 -->
                      <div class="right aligned four wide column">
                        <a target="_blank" class="ui my-blue basic label m-padded-tiny m-text-thin">{{item.typeName}}</a>
                      </div>
                    </div>
                  </div>
                   <!-- 剩下的行数右边对其 -->
                   <!-- 5.18 用1来占据剩余空间 -->
                  <div class="seven wide column" style="width: '100%';height:'100%';display: flex; overflow: hidden;position: relative;padding:0; margin :0; flex-grow: 1;">
                    <a target="_blank">
                      <img v-bind:src=item.firstPicture @click="toBlog(item.blogId)" alt="" class="ui rounded image" style="width:'100%';height:'100%'; object-fit: cover;object-position: center;">
                    </a>
                  </div>
                </div>
                  </div>
                <!-- </el-card> -->
              </div>
            <!-- </div> -->

            <!--footer-->
            <div class="ui bottom attached segment my-blog-shadow" :style="{width: '100%'}">
              <div class="pagination-container">
                <el-pagination
                  class="pagiantion"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pagination.currentPaege"
                  :page-sizes="[2,6,10,15]"
                  :page-size="pagination.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pagination.total">
                </el-pagination>
              </div>
            </div>

          </div>

          <!--右边的top-->
          <div class="five wide column blog-recommend" style="left: 34px !important;">
            <!--分类-->
            <div class="ui segments my-shadow" style=" background-color: rgba(255, 255, 255, 0.5) !important;">
              <div class="ui secondary segment">
                <div class="ui two column grid">
                  <div class="column">
                    <i class="idea icon"></i>分类
                  </div>
                  <div class="right aligned column">
                    <a href="/types" target="_blank">more <i class="angle double right icon"></i></a>
                  </div>
                </div>
              </div>
              <div class="ui my-blue segment">
                <div class="ui fluid vertical menu">
                  <template v-for="(item, index) in typeList">
                    <a class="item" v-if='index<6' :key="item.typeId" @click="toType(item.typeId)">
                      {{ item.typeName }}
                      <div class="ui blue basic left pointing label">{{item.typeCount}}</div>
                    </a>
                  </template>
                </div>
              </div>
            </div>
              <!--标签-->
              <div class="ui segments m-margin-top-large my-shadow" style=" background-color: rgba(255, 255, 255, 0.5) !important;">
                <div class="ui secondary segment">
                  <div class="ui two column grid">
                    <div class="column">
                      <i class="tags icon"></i>标签
                    </div>
                    <div class="right aligned column">
                      <a href="/tags" target="_blank">more <i class="angle double right icon"></i></a>
                    </div>
                  </div>
                </div>
                <div class="ui my-blue segment">
                  <template v-for="(item, index) in tagList">
                    <a target="_blank" class="ui my-blue basic left pointing label m-margin-tb-tiny" v-if='index<15' :key="item.tagId" @click="toTag(item.tagId)">
                      {{item.tagName}} <div class="detail">{{item.tagCount}}</div>
                    </a>
                  </template>
                </div>
              </div>

              <!--最新推荐-->
              <div class="ui segments m-margin-top-large my-shadow" style=" background-color: rgba(255, 255, 255, 0.5) !important;">
                <div class="ui secondary segment ">
                  <i class="bookmark icon"></i>最新推荐
                </div>
                <div class="ui segment" v-for="item in latestList" :key="item.blogId">
                  <a  target="_blank" class="m-black m-text-thin" style="cursor:pointer;" v-text="item.title" @click="toBlog(item.blogId)"></a>
                </div>
              </div>
              <!-- 时间胶囊 -->
              <div class="ui segments m-margin-top-large my-shadow" style=" background-color: rgba(255, 255, 255, 0.8) !important;">
                 <div class="date">
    <div class="item" id="dayProgress">
      <div class="date-text" style="margin-top: 0rem;">今日已经度过了&nbsp;<span>{{ todayHours }}</span>&nbsp;小时</div>
      <div class="progress">
        <div class="progress-bar" :style="{ width: todayHoursPercent + '%' }"></div>
      </div>
    </div>

    <div class="item" id="weekProgress">
      <div class="date-text">本周已经度过了&nbsp;<span>{{ weekDay }}</span>&nbsp;天</div>
      <div class="progress">
        <div class="progress-bar" :style="{ width: weekDayPassPercent + '%' }"></div>
      </div>
    </div>

    <div class="item" id="monthProgress">
      <div class="date-text">本月已经度过了&nbsp;<span>{{ currentDate }}</span>&nbsp;天</div>
      <div class="progress">
        <div class="progress-bar" :style="{ width: monthPassPercent + '%' }"></div>
      </div>
    </div>

    <div class="item" id="yearProgress">
      <div class="date-text">今年已经度过了&nbsp;<span>{{ currentMonth }}</span>&nbsp;个月</div>
      <div class="progress">
        <div class="progress-bar" :style="{ width: yearPass + '%' }"></div>
      </div>
    </div>
  </div>
              </div>

              <!--二维码-->
              <!-- <h4 class="ui horizontal divider header m-margin-top-large">博客交流Q群822628221</h4> -->
              <!--            <div class="ui centered card my-shadow" style="width: 11em">-->
              <!--              <img src="https://tcefrep.oss-cn-beijing.aliyuncs.com/blog/image/IMG_1833.JPG" alt="" class="ui rounded image" >-->
              <!--            </div>-->
          </div>

        </div>
      </div>
    </div>
    <!-- <div id="toolbar" class="m-padded m-fixed m-right-bottom" >
      <div class="ui vertical icon buttons ">
        <button type="button" class="ui blue button" @click="openWeChatPay">资料
        </button>
        <div id="toTop-button" class="ui icon button" ><i class="chevron up icon"></i></div>
      </div>
    </div> -->
    <br>
    <br>
<!--    &lt;!&ndash; 主体组件 &ndash;&gt;-->
<!--    <index style="width: 100%"></index>-->
    <Footer></Footer>
  </div>

</template>

<script>
import { Notification } from 'element-ui'
import Footer from '../components/layout/Footer'
export default {
  // 注册组件
  components: {
    Footer
  },
  data () {
    return {
      todayHours: 0,
      todayHoursPercent: 0,
      weekDay: 0,
      weekDayPassPercent: 0,
      currentDate: 0,
      monthAll: 0,
      monthPassPercent: 0,
      currentMonth: 0,
      yearPass: 0,
      dataList: [], // 当前页要展示的博客分页列表数据
      typeList: [], // 分类列表的数据
      tagList: [], // 标签列表的数据
      latestList: [], // 最新发布的博客列表的数据
      pagination: { // 分页相关模型数据
        currentPage: 1, // 当前页码
        pageSize: 6, // 每页显示的记录数
        total: 0, // 总记录数
        queryString: null // 查询条件
      },
      tip: false,
      obj: {
        isEnd: false,
        speed: 300,
        singleBack: false,
        sleep: 0,
        type: 'rollback',
        backSpeed: 40,
        sentencePause: true
      },
      articleList: [],
      blogInfo: {},
      current: 1
    }
  },
  created () {
    this.message()
    this.findPage()
    this.getTypeList()
    this.getTagList()
    this.getLatestList()
    if (this.$store.state.music) {
      this.$store.state.music = false
      window.location.reload()
    }
  },
  methods: {
    updateLifetime () {
      const nowDate = +new Date();
      const todayStartDate = new Date(new Date().toLocaleDateString()).getTime();
      this.todayHours = Math.floor((nowDate - todayStartDate) / 1000 / 60 / 60);
      this.todayHoursPercent = Math.floor((this.todayHours / 24) * 100);

      const weeks = { 0: 7, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 };
      this.weekDay = weeks[new Date().getDay()];
      this.weekDayPassPercent = Math.floor((this.weekDay / 7) * 100);

      const year = new Date().getFullYear();
      this.currentDate = new Date().getDate();
      this.month = new Date().getMonth() + 1;
      this.monthAll = new Date(year, this.month, 0).getDate();
      this.monthPassPercent = Math.floor((this.currentDate / this.monthAll) * 100);

      this.currentMonth = this.month;
      this.yearPass = Math.floor((this.month / 12) * 100);
    },
    openWeChatPay () {
      this.$store.state.payFlag = true
    },
    message () {
      const messageFlag = this.$store.state.messageFlag
      if (messageFlag == null) {
        Notification({
          title: '消息',
          message: '在本网站中可以进行发布博客评论相关的东西，但是恶意破坏本站以及一些没有意义的东西会被我删除，谢谢',
          duration: 0
        })
        const h = this.$createElement
        Notification({
          title: '通知',
          message: h('i', { style: 'color: teal' }, '目前功能尚不完善，最近音乐盒会有我经常听的音乐'),
          position: 'top-left',
          duration: 0
        })
      }
      this.$store.state.messageFlag = 'true'
    },
    // 初始化
    scrollDown () {
      window.scrollTo({
        behavior: 'smooth',
        top: document.documentElement.clientHeight
      })
    },
    search () {
      this.findPage()
      this.pagination.queryString = null
    },
    toTag (tagId) {
      this.$router.push({ path: "/tags/" + tagId });
    },
    toType (typeId) {
      this.$router.push({ path: "/types/" + typeId });
    },
    toBlog (blogId) {
      this.$store.state.searchFlag = false;
      this.$store.state.blogId = blogId;
      this.$router.push({ path: "/blog/" + blogId });
    },
    async getLatestList () {
      const { data: res } = await this.$http.get('/api/server/home/latestList')
      this.latestList = res.data
    },
    async getTypeList () {
      const { data: res } = await this.$http.get('/api/server/home/getTypeCount')
      this.typeList = res.data
    },
    async getTagList () {
      const { data: res } = await this.$http.get('/api/server/home/getTagCount')
      this.tagList = res.data
    },
    // 分页查询
    async findPage () {
      const str = this.$store.state.queryString
      if (str !== null) {
        this.pagination.queryString = str
        this.$store.state.queryString = null
        this.$message.info('搜索结果已经显示在页面下方')
      }
      // 发送ajax，提交分页请求（页码，每页显示条数，查询条件)
      const param = {
        currentPage: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        queryString: this.pagination.queryString
      }
      const { data: res } = await this.$http.post('/api/server/home/findHomePage', param)
      // 解析controller响应回的数据
      if (!res.flag) {
        return this.$message.error('获取博客列表失败！')
      }
      this.pagination.total = res.data.total
      this.dataList = res.data.records
    },
    handleCurrentChange (currentPage) {
      // 设置最新的页码
      this.pagination.currentPage = currentPage
      // 重新调用findPage方法进行分页查询
      this.findPage()
    },
    handleSizeChange (newSize) {
      this.pagination.pageSize = newSize
      this.findPage()
    },
  },
  mounted () {
    this.updateLifetime();
    setInterval(this.updateLifetime, 1000);
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })
    $('.ui.dropdown').dropdown({
      on: 'hover'
    })
    $('#toTop-button').click(function () {
      $(window).scrollTo(0, 500)
    })
    $('.wechat').popup({
      popup: $('.wechat-qr'),
      position: 'top center'
    })
    $('.qq').popup()
    $('.github').popup()
  }
}
</script>
<style scoped>
.wenzhangzongshu{
  color: black;
  font-weight: bold;

}
.img-g{
  width: 70px;
  height: 70px;
border: #ffffff solid 5px;
display: block;
margin: 50px auto;
border-radius: 50%;
transition: all 1.0s;
}
.img-g:hover{
transform: rotate(360deg);}

/* 图片翻转效果 */
.ui.grid .seven.wide.column {
  flex-grow: 1; /* 让该列在可用空间内自动扩展 */
  display: flex; /* 为了兼容性，确保使用flex布局 */
  align-items: stretch; /* 如果需要，使内容撑满该列的高度 */
}
/* 111 */
.ui.avatar.image {
  border-radius: 50%; /* 使头像圆形 */
  overflow: hidden; /* 确保超出的图片部分被隐藏 */
  width: 23px;
  height: 23px;
}
.ui.grid > .row {
  margin: 0;
  padding: 0;
}

.ui.grid > .row > .column {
  padding: 0;
  margin: 0;
  /* 如果需要，可以设置高度为100%以确保内容填满列的高度 */
  height: 100%;
  box-sizing: border-box;
}

/* 如果有图片或其他内容，确保它们也填充满 */
.ui.grid img,
.ui.grid > .row > .column > * {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 或其他适当的object-fit值，如contain */
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
/* 添加到你的样式文件中 */
.card-v {
  /* 确保卡片是一个相对定位的容器，以便绝对定位的图像在其内放大 */
  /* width: 90%;
  height: 200px; */
  width: '80%';
  box-sizing: border-box; /* 确保边框和内边距计算在总宽高内 */
  padding: 0;
  margin: 0;
  height: 200px;
  position: relative;
  overflow: hidden; /* 隐藏超出卡片边界的内容 */
}
.card-x{
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

.ui.mobile.reversed.stackable.grid {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  gap: 0px;
  object-fit: cover;
}
.image{
  /* 初始状态的图片样式 */
  width: 100%; /* 或其他初始宽度 */
  height: 100%;
  transition: transform 0.5s ease; /* 平滑过渡效果 */
}

/* 当鼠标悬停在卡片上时，改变图片的大小 */
.card-x:hover{
  transform: scale(1.05); /* 图片放大1.1倍 */
}
.image:hover{
    transform: scale(1.2); /* 图片放大1.1倍 */
}

.image-container img {
  /* 使图片填满div */
  width: 100%;
  height: 100%;
  object-fit: cover; /* 关键属性，保持图片宽高比并填充容器 */
  object-position: center; /* 可选，定义图片在容器中的对齐方式，这里是居中 */
}
 .m-home {
    padding-top: 105vh !important;
    padding-bottom: 0px !important;
  }
  .home-banner {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    height: 100vh;
    background: url("../assets/images/71f294ddb4a4984817cb5c6c1e3e6102.jpg") center center /
    cover no-repeat;
    background-color: #49b1f5;
    background-attachment: fixed;
    text-align: center;
    /*color: #fff !important;*/
    animation: header-effect 1s !important;
  }
  .banner-container {
    margin-top: 23vh;
    line-height: 1.5;
    color: #eee;
  }
  .blog-contact a {
    color: #fff !important;
    /* background-color: rgba(255, 255, 255, 0.5); */
  }
  .card-info-social {
    line-height: 40px;
    text-align: center;
    font-size: 1.5rem;
    margin: 6px 0 -6px;
  }
  .left-radius {
    border-radius: 8px 0 0 8px !important;
    order: 0;
  }
  .right-radius {
    border-radius: 0 8px 8px 0 !important;
    order: 1;
  }
  .article-wrapper {
    font-size: 14px;
  }
  @media (min-width: 760px) {
    .blog-title {
      font-size: 3rem;
background-image: -webkit-gradient( linear, left top, right top, color-stop(0, #f22), color-stop(0.15, #f2f), color-stop(0.3, #22f), color-stop(0.45, #2ff), color-stop(0.6, #2f2),color-stop(0.75, #2f2), color-stop(0.9, #ff2), color-stop(1, #f22) );
color: transparent;
-webkit-background-clip: text;
-moz-background-clip: text;
-ms-background-clip: text
    }
    /* 设置博客简介字体大小为1.5 */
    .blog-intro {
      font-size: 1.5rem;
    }
    /* 定义联系信息的行高、文本对齐方式、字体大小及外边距。 */
    .blog-contact {
      line-height: 40px;
      text-align: center;
      font-size: 1.5rem;
      margin: 6px 0 -6px;
    }
    /* 限制主页容器的最大宽度为1200px，并居中显示，设置了上边距和内边距。 */
    .home-container {
      max-width: 1200px;
      margin: calc(100vh - 50px) auto 0 auto;
      padding: 0 3px;
    }
    /* 创建文章卡片的样式，使用Flex布局，设置了高度、宽度、上边距以及鼠标悬停时图片的放大效果。 */
    .article-card {
      display: flex;
      align-items: center;
      height: 280px;
      width: 100%;
      margin-top: 20px;
      /* 卡片圆角，鼠标放上去才有圆角 */
       /* border-radius: 20px / 10px; */
       /* background:rgba(255,0,0,0.5); */
    }
    /* 定义文章封面图的样式，包括溢出隐藏、高度、宽度等，并在小屏下增加了圆角。 */
    .article-cover {
      overflow: hidden;
      height: 100%;
      width: 45%;
    }
    /* 定义了一个通用的过渡效果，用于平滑地改变样式，如缩放。 */
    .on-hover {
      transition: all 0.6s;
    }
    .article-card:hover .on-hover {
      transform: scale(1.1);
    }
    /* 设置文章内容区域的内边距、链接字体大小和过渡效果。 */
    .article-wrapper {
      padding: 0 2.5rem;
      width: 55%;
    }
    .article-wrapper a {
      font-size: 1.5rem;
      transition: all 0.3s;
    }
  }
  @media (max-width: 759px) {
    .blog-title {
          font-size: 3rem;
background-image: -webkit-gradient( linear, left top, right top, color-stop(0, #f22), color-stop(0.15, #f2f), color-stop(0.3, #22f), color-stop(0.45, #2ff), color-stop(0.6, #2f2),color-stop(0.75, #2f2), color-stop(0.9, #ff2), color-stop(1, #f22) );
color: transparent;
-webkit-background-clip: text;
-moz-background-clip: text;
-ms-background-clip: text
    }
    .blog-contact {
      font-size: 1.25rem;
      line-height: 2;
    }
    .home-container {
      width: 100%;
      margin: calc(100vh - 66px) auto 0 auto;
    }
    .blog-recommend {
      display: none !important;
    }
    /* 文章信息、作者信息区域、博客卡片等元素的样式。 */
    .article-card {
      margin-top: 1rem;
      /* background-color: rgba(255, 255, 255, 0.5); 添加此行代码，将颜色设为白色并设置半透明效果，透明度0.5 */
    }
    .article-cover {
      border-radius: 8px 8px 0 0 !important;
      height: 230px !important;
      width: 100%;
    }
    .article-cover div {
      border-radius: 8px 8px 0 0 !important;
    }
    /* 物品包装器 */
    .article-wrapper {
      padding: 1.25rem 1.25rem 1.875rem;
    }
    .article-wrapper a {
      font-size: 1.25rem;
      transition: all 0.3s;
    }
  }
  .scroll-down {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .scroll-down i {
    font-size: 2rem;
  }
  .article-wrapper a:hover {
    color: #8e8cd8;
  }
  .article-info {
    font-size: 95%;
    color: #858585;
    line-height: 2;
    margin: 0.375rem 0;
  }
  .article-info a {
    font-size: 95%;
    color: #858585 !important;
  }
  .article-content {
    line-height: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .blog-wrapper {
    position: sticky;
    top: 10px;
  }
  .blog-card {
    line-height: 2;
    padding: 1.25rem 1.5rem;
     /* background-color: rgba(255, 255, 255, 0.5); 添加此行代码，将颜色设为白色并设置半透明效果，透明度0.5 */
  }
  .el-card{
    background-color: rgba(255, 255, 255, 0.7);
  }
  /* 作者包装器 */
  .author-wrapper {
    text-align: center;
  }
  .blog-info-wrapper {
    display: flex;
    justify-self: center;
    padding: 0.875rem 0;
  }
  .blog-info-data {
    flex: 1;
    text-align: center;
  }
  .blog-info-data a {
    text-decoration: none;
  }
  .collection-btn {
    text-align: center;
    z-index: 1;
    font-size: 14px;
    position: relative;
    display: block;
    background-color: #49b1f5;
    color: #fff !important;
    height: 32px;
    line-height: 32px;
    transition-duration: 1s;
    transition-property: color;
  }
  .collection-btn:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #ff7242;
    content: "";
    transition-timing-function: ease-out;
    transition-duration: 0.5s;
    transition-property: transform;
    transform: scaleX(0);
    transform-origin: 0 50%;
  }
  .collection-btn:hover:before {
    transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
    transform: scaleX(1);
  }
  .author-avatar {
    transition: all 0.5s;
  }
  .author-avatar:hover {
    transform: rotate(360deg);
  }
  .web-info {
    padding: 0.25rem;
    font-size: 0.875rem;
  }
  /* 时间胶囊样式 */
  .progress {
    width: 100%;
    height: 20px;
    align-items: center;
    background: rgb(0 0 0 / 0%) !important;
    backdrop-filter: blur(5px);
}

.progress-bar {
    font-family: 'UnidreamLED' !important;
    background-color: #21c712 !important;
    color: rgb(86 77 89) !important;
    font-size: 0.95rem;
    height: 10px;
     border-radius: 10px; /* 添加圆角，数字越大圆角越圆滑 */
}

.date {
    width: 100%;
}

.date-text {
    margin: 1rem 0rem 0.5rem 0rem;
}
  /*  */
  .scroll-down-effects {
    color: #eee !important;
    text-align: center;
    text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
    line-height: 1.5;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    animation: scroll-down-effect 1.5s infinite;
  }
  @keyframes scroll-down-effect {
    0% {
      top: 0;
      opacity: 0.4;
      filter: alpha(opacity=40);
    }
    50% {
      top: -16px;
      opacity: 1;
      filter: none;
    }
    100% {
      top: 0;
      opacity: 0.4;
      filter: alpha(opacity=40);
    }
  }
  .big i {
    color: #f00;
    animation: big 0.8s linear infinite;
  }
  @keyframes big {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
</style>
