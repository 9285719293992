<template>
    <div class="tags">
      <div class="tags-banner banner">
        <h1 class="banner-title">标签</h1>
      </div>
      <!--中间内容-->
      <div  class="m-container-small m-tags">
        <div class="ui container">
          <!--header-->
          <div class="ui top attached segment my-shadow">
            <div class="ui middle aligned two column grid">
              <div class="column">
                <h3 class="ui my-blue header">标签</h3>
              </div>
              <div class="right aligned column">
                共 <h2 class="ui orange header m-inline-block m-text-thin"> {{tagList.length}} </h2> 个
              </div>
            </div>
          </div>

          <div class="ui attached segment m-padded-tb-large my-shadow">
            <template v-for="item in tagList">
              <div class="ui labeled button m-margin-tb-tiny" @click="saveNavState(item)" v-if="activeId===item.tagId" :key="item.tagId">
                <a class="ui blue basic button">{{item.tagName}}</a>
                <div class="ui basic blue left pointing label">{{item.tagCount}}</div>
              </div>
              <div class="ui labeled button m-margin-tb-tiny" @click="saveNavState(item)" v-else :key="item.tagId">
                <a class="ui basic button">{{item.tagName}}</a>
                <div class="ui basic left pointing label">{{item.tagCount}}</div>
              </div>
            </template>
          </div>

          <!--content-->
          <!-- <div class="ui attached  segment my-blog-shadow">

            <div class="ui padded vertical segment m-padded-tb-large" v-for="item in dataList" :key="item.blogId">
              <div class="ui middle aligned mobile reversed stackable grid">
                <div class="eleven wide column" style="cursor:pointer;">
                  <h3 class="ui header" @click="toBlog(item.blogId)">{{item.title}}</h3>
                  <p class="m-text" @click="toBlog(item.blogId)">{{item.description}}</p>
                  <div class="ui grid">
                    <div class="eleven wide column">
                      <div class="ui mini horizontal link list">
                        <div class="item">
                          <img v-bind:src=item.avatar class="ui avatar image">
                          <div class="content"><a class="header">{{item.nickname}}</a></div>
                        </div>
                        <div class="item">
                          <i class="calendar icon"></i> {{item.createTime}}
                        </div>
                        <div class="item">
                          <i class="eye icon"></i> {{item.views}}
                        </div>
                        <div class="item">
                          <i class="thumbs up outline icon"></i> {{item.thumbs}}
                        </div>
                      </div>
                    </div>
                    <div class="right aligned five wide column">
                      <a target="_blank" class="ui my-blue basic label m-padded-tiny m-text-thin">{{item.typeName}}</a>
                    </div>
                  </div>
                </div>
                <div class="five wide column">
                  <a target="_blank">
                    <img v-bind:src=item.firstPicture @click="toBlog(item.blogId)" alt="" class="ui rounded image">
                  </a>
                </div>

              </div>
            </div>
          </div> -->
 <div class="ui padded vertical segment m-padded-tb-largev" v-for="item in dataList" :key="item.blogId">
                <!-- <el-card class="card-v" style="padding:0;margin:0"> -->
                  <div class="card-x" style="width:'100%';height:220px;padding:0;margin:0; background-color: rgba(255, 255, 255, 0.7);position: relative;overflow: hidden;">
                  <!-- 卡片中定义网格布局 -->
                <div class="ui mobile reversed stackable grid" style="width: 100%; height: 100%;gap: 0; padding:0;margin:0">
                  <!-- 左边九列布局 -->
                  <div class="nine wide column" style="cursor:pointer;">
                    <!-- 九列布局第一行 hearder占位 -->
                        <h3 class="ui header" style="color:rgb(135, 206, 235);display: flex;justify-content: center;align-items: center;margin-bottom:20px;margin-top:20px" @click="toBlog(item.blogId)">{{item.title}}</h3>
                        <!-- 九列布局第二行又是一个网格布局 -->
                    <div class="ui grid" style="display: flex;justify-content: start;align-items: center; margin-top: 50px; margin-bottom: 20px;margin-left:20px" >
                      <!-- 第一行文本介绍 -->
                    <p class="m-text" @click="toBlog(item.blogId)">{{item.description}}</p>
                    <!-- 占据11列的宽度 -->
                      <div class="twelve wide column" style="display: flex;justify-content:start;">
                        <div class="ui mini horizontal link list">
                          <div class="item">
                            <img v-bind:src=item.avatar class="ui avatar image">
                            <div class="content"><a class="header">{{item.nickname}}</a></div>
                          </div>
                          <div class="item">
                            <i class="calendar icon"></i> {{item.createTime}}
                          </div>
                          <div class="item">
                            <i class="eye icon"></i> {{item.views}}
                          </div>
                          <div class="item">
                            <i class="thumbs up outline icon"></i> {{item.thumbs}}
                          </div>
                        </div>
                      </div>
                      <!-- 剩下的行数右边对其 -->
                      <div class="right aligned four wide column">
                        <a target="_blank" class="ui my-blue basic label m-padded-tiny m-text-thin">{{item.typeName}}</a>
                      </div>
                    </div>
                  </div>
                   <!-- 剩下的行数右边对其 -->
                   <!-- 5.18 用1来占据剩余空间 -->
                  <div class="seven wide column" style="width: '100%';height:'100%';display: flex; overflow: hidden;position: relative;padding:0; margin :0; flex-grow: 1;">
                    <a target="_blank">
                      <img v-bind:src=item.firstPicture @click="toBlog(item.blogId)" alt="" class="ui rounded image" style="width:'100%';height:'100%'; object-fit: cover;object-position: center;">
                    </a>
                  </div>
                </div>
                  </div>
                <!-- </el-card> -->
              </div>

          <!--footer-->
          <div class="ui bottom attached segment my-blog-shadow">
            <div class="pagination-container">
              <el-pagination
                class="pagiantion"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.currentPage"
                :page-sizes="[2,6,10,15]"
                :page-size="pagination.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pagination.total">
              </el-pagination>
            </div>
          </div>

        </div>
      </div>

      <br>
      <br>
    </div>
</template>

<script>
export default {
  data () {
    return {
      activeId: '', // 被激活的标签id
      tagList: [], // 标签列表的数据
      dataList: [],
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: '',
      pagination: { // 分页相关模型数据
        currentPage: 1, // 当前页码
        pageSize: 6, // 每页显示的记录数
        total: 0, // 总记录数
        queryString: null // 查询条件
      }
    }
  },
  created () {
    this.findPage(this.activeId)
    this.getTagList()
  },
  methods: {
    async getTagList () {
      const { data: res } = await this.$http.get('/api/server/home/getTagCount')
      this.tagList = res.data
    },
    toBlog (blogId) {
      this.$store.state.blogId = blogId;
      this.$router.push({ path: "/blog/" + blogId });
    },
    async saveNavState (item) {
      this.activeId = item.tagId
      if (item.tagCount === 0) {
        this.$message.error('查询失败，当前所在标签的博客数为0')
      } else {
        await this.findPage(this.activeId)
      }
    },
    // 分页查询
    async findPage (tagId) {
      var id = this.$route.path.split('/tags/')[1]
      if (tagId === '') {
        tagId = id
      }
      this.activeId = tagId
      this.$store.state.tagId = null
      // 发送ajax，提交分页请求（页码，每页显示条数，查询条件)
      const param = {
        currentPage: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        queryString: this.pagination.queryString,
        tagId: tagId
      }
      const { data: res } = await this.$http.post('/api/server/tagShow/getById', param)
      // 解析controller响应回的数据
      if (!res.flag) {
        return this.$message.error('获取首页列表失败！')
      }
      this.pagination.total = res.data.total
      this.dataList = res.data.records
    },
    handleCurrentChange (currentPage) {
      // 设置最新的页码
      this.pagination.currentPage = currentPage
      // 重新调用findPage方法进行分页查询
      this.findPage()
    },
    handleSizeChange (newSize) {
      this.pagination.pageSize = newSize
      this.findPage()
    }
  },
  mounted () {
    $('.ui.dropdown').dropdown({
      on: 'hover'
    })
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })
  }
}
</script>

<style scoped>
  .container{
    animation: main 1s;
  }
  .m-tags {
    padding-top: 69vh !important;
    padding-bottom: 0px !important;
  }
  .tags-banner {
    height:  100vh;
    /* 未闻花名晚上照片，显示不全 */
    background: url(https://wwaffle.oss-cn-beijing.aliyuncs.com/blog/htmlbannerr/595656780d1010365a9a97bb0e85d0e0.jpg) center
    center / cover no-repeat;
    background-color: #49b1f5;
  }
  /* 添加到你的样式文件中 */
.card-v {
  /* 确保卡片是一个相对定位的容器，以便绝对定位的图像在其内放大 */
  /* width: 90%;
  height: 200px; */
  width: '80%';
  box-sizing: border-box; /* 确保边框和内边距计算在总宽高内 */
  padding: 0;
  margin: 0;
  height: 200px;
  position: relative;
  overflow: hidden; /* 隐藏超出卡片边界的内容 */
}
.card-x{
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

.ui.mobile.reversed.stackable.grid {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  gap: 0px;
  object-fit: cover;
}
.image{
  /* 初始状态的图片样式 */
  width: 100%; /* 或其他初始宽度 */
  height: 100%;
  transition: transform 0.5s ease; /* 平滑过渡效果 */
}

/* 当鼠标悬停在卡片上时，改变图片的大小 */
.card-x:hover{
  transform: scale(1.05); /* 图片放大1.1倍 */
}
.image:hover{
    transform: scale(1.2); /* 图片放大1.1倍 */
}

.image-container img {
  /* 使图片填满div */
  width: 100%;
  height: 100%;
  object-fit: cover; /* 关键属性，保持图片宽高比并填充容器 */
  object-position: center; /* 可选，定义图片在容器中的对齐方式，这里是居中 */
}
</style>
